import { MyMenuType } from '../graphql/generated'
import { type Group } from '../providers/GroupProvider'
import { routes } from './routes'

export type ActiveOn = string | string[]

enum NavigationItemType {
  Dropdown = 'DROPDOWN',
  External = 'EXTERNAL',
  Link = 'LINK',
  Toolbar = 'TOOLBAR',
}

interface BaseNavigationItem {
  activeOn?: ActiveOn
  'data-cy'?: string
  'data-gtm-name'?: string
  featured?: boolean
  hidden?: boolean
  title: string
}

export interface NavigationLink extends BaseNavigationItem {
  to: string
  type: NavigationItemType.Link
}

export interface NavigationLinkExternal extends BaseNavigationItem {
  to: string
  type: NavigationItemType.External
}

export interface NavigationDropdown extends BaseNavigationItem {
  children?: NavigationDropdown[]
  to?: string
  type: NavigationItemType.Dropdown
}

export interface NavigationToolbar extends BaseNavigationItem {
  to: string
  type: NavigationItemType.Toolbar
}

export type NavigationItem = NavigationLink | NavigationDropdown | NavigationToolbar | NavigationLinkExternal

const buildNavLinks = (group: Group): NavigationItem[] => [
  {
    children: [
      {
        activeOn: routes.products.path,
        'data-cy': 'main-navigation-my-music',
        'data-gtm-name': 'main-navigation-my-music',
        hidden: !group?.myMenu?.includes(MyMenuType.Releases),
        title: 'Releases',
        to: routes.products.overview.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.stats.path,
        'data-cy': 'main-navigation-stats',
        'data-gtm-name': 'main-navigation-stats',
        hidden: !group?.myMenu?.includes(MyMenuType.Stats),
        title: 'Stats',
        to: routes.stats.overview.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.myCollabs.path,
        'data-cy': 'main-navigation-my-collabs',
        'data-gtm-name': 'main-navigation-my-collabs',
        hidden: !group?.myMenu?.includes(MyMenuType.Collabs),
        title: 'Collabs',
        to: routes.myCollabs.overview.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.assets.overview.licenses.url,
        'data-cy': 'main-navigation-my-licenses',
        'data-gtm-name': 'main-navigation-my-licenses',
        hidden: !group?.myMenu?.includes(MyMenuType.MlLicenses),
        title: 'ML Licenses',
        to: routes.assets.overview.licenses.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.publishingClearances.overview.url,
        'data-cy': 'main-navigation-publishing-clearances',
        'data-gtm-name': 'main-navigation-publishing-clearances',
        hidden: !group?.myMenu?.includes(MyMenuType.PublishingSplits),
        title: 'Publishing clearances',
        to: routes.publishingClearances.overview.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.assets.overview.royaltySplits.url,
        'data-cy': 'main-navigation-my-royalty-splits',
        'data-gtm-name': 'main-navigation-my-royalty-splits',
        hidden: !group?.myMenu?.includes(MyMenuType.RoyaltySplits),
        title: 'Royalty splits',
        to: routes.assets.overview.royaltySplits.url,
        type: NavigationItemType.Dropdown,
      },
      {
        activeOn: routes.assets.overview.nfts.url,
        'data-cy': 'main-navigation-my-nfts',
        'data-gtm-name': 'main-navigation-my-nfts',
        hidden: !group?.myMenu?.includes(MyMenuType.Nfts),
        title: 'NFTs',
        to: routes.assets.overview.nfts.url,
        type: NavigationItemType.Dropdown,
      },
    ],
    featured: true,
    title: 'My Collabhouse',
    type: NavigationItemType.Dropdown,
  },

  {
    'data-gtm-name': 'main-navigation-music-library',
    title: 'Music Library',
    to: routes.external.library.url,
    type: NavigationItemType.External,
  },
  {
    'data-gtm-name': 'main-navigation-campaigns',
    title: 'Campaigns',
    to: routes.external.campaigns.overview.url,
    type: NavigationItemType.External,
  },
  {
    activeOn: routes.collaboration.path,
    'data-gtm-name': 'main-navigation-collab"',
    title: 'Collab Market',
    to: routes.collaboration.overview.allTeams.url,
    type: NavigationItemType.Link,
  },
  {
    activeOn: routes.nftMarket.path,
    'data-gtm-name': 'main-navigation-nft-market"',
    title: 'NFT Market',
    to: routes.nftMarket.overview.url,
    type: NavigationItemType.Link,
  },

  {
    activeOn: routes.chat.path,
    'data-gtm-name': 'main-navigation-chat"',
    title: 'Chat',
    to: routes.chat.url,
    type: NavigationItemType.Toolbar,
  },
]
export default buildNavLinks
