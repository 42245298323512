interface BaseMusicLibraryContentType {
  desc: string
  title: string
}

export interface MusicLibraryContentTypeParent extends BaseMusicLibraryContentType {
  children: MusicLibraryContentTypeChild[]
}

export interface MusicLibraryContentTypeChild extends BaseMusicLibraryContentType {
  key: string
}

export type MusicLibraryContentType = MusicLibraryContentTypeParent | MusicLibraryContentTypeChild

export interface MusicLibraryContentTypeCategory {
  children: MusicLibraryContentType[]
  desc: string
  title: string
}

export const socialMediaCategory = {
  children: [
    {
      desc: "Non-branded and non-sponsored social media posts only that don't include advertisement",
      key: 'socialMediaNonBranded',
      title: 'Non Branded (excl Ads)',
    },
    {
      desc: 'Branded or sponsored social media posts and posts that include advertisement',
      key: 'socialMediaBranded',
      title: 'Branded (incl Ads)',
    },
  ],
  desc: 'Includes use of the track in content you post on social media platforms such as Facebook (Meta), Instagram, TikTok, YouTube, Twitch, Triller, Snapchat, X',
  title: 'Social Media',
}

export const podcastCategory = {
  children: [
    {
      desc: 'Non-branded and non-sponsored content only that does not include advertisement',
      key: 'podcastPrivateNonBranded',
      title: 'Non Branded (excl Ads)',
    },
    {
      desc: 'Branded or sponsored content and content that includes advertisement',
      key: 'podcastPrivateBranded',
      title: 'Branded (incl Ads)',
    },
  ],
  desc: 'Includes use of the track in a podcast and use during a live stream (including Twitch etc).',
  title: 'Podcast & Live Streaming',
}

export const corporateNonProfitCategory = {
  children: [
    {
      desc: 'Company presentation or event',
      key: 'corporateNonProfitCompanyPresentation',
      title: 'Company Presentation',
    },
    {
      desc: 'Educational content',
      key: 'corporateNonProfitEducational',
      title: 'Educational',
    },
    {
      desc: 'Content published by a non-profit or charity organization',
      key: 'corporateNonProfitCharity',
      title: 'Non-Profit / Charity',
    },
    {
      desc: 'Private film',
      key: 'corporatePrivateFilm',
      title: 'Private Film',
    },
  ],
  desc: 'Includes use of the track in content published or used in and during corporate presentations and events, by non-profit and charity organizations and for educational purposes',
  title: 'Corporate & Non-Profit',
}

export const gamesAppsCategory = {
  children: [
    {
      desc: 'Background music and soundtracks for in-game and in-app content',
      key: 'gamesAppsInAppGame',
      title: 'In Game & In App',
    },
    {
      desc: 'Game trailer or promotional material for an App',
      key: 'gamesAppsTrailerPromo',
      title: 'Trailer / Promo',
    },
  ],
  desc: 'Includes use of the track in a game or App, in a game trailer or promotional material for an App ',
  title: 'Game & App',
}

export const advertisementCategory = {
  children: [
    {
      desc: 'Advertisement in all types of media (TV, radio, online)',
      key: 'advertisementAllMedia',
      title: 'All Media',
    },
    {
      desc: 'Online advertisement only',
      key: 'advertisementOnlineOnly',
      title: 'Online Only',
    },
    {
      desc: 'Radio advertisement',
      key: 'advertisementAudioOnly',
      title: 'Radio Only',
    },
  ],
  desc: '',
  title: 'Advertisement',
}

export const radioTvCategory = {
  children: [
    {
      desc: 'TV show or program',
      key: 'radioTvTvShow',
      title: 'TV show',
    },
    {
      desc: 'Trailer or promo for a TV show or program',
      key: 'radioTvTrailer',
      title: 'TV Show Trailer',
    },
    {
      desc: 'Radio show or promo for a radio show',
      key: 'radioTvAudioOnly',
      title: 'Radio',
    },
  ],
  desc: "Includes use of the track in content broadcast on TV or radio, including TV and Radio Program trailers and promo's",
  title: 'Radio & TV Broadcasting',
}

export const filmCategory = {
  children: [
    {
      children: [
        {
          desc: 'Online, cinema, TV',
          key: 'filmSmallAllMedia',
          title: 'All Media',
        },
        {
          desc: 'Online only',
          key: 'filmSmallOnlineOnly',
          title: 'Online Only',
        },
        {
          desc: 'Trailer for a film, series or documentary',
          key: 'filmSmallTrailer',
          title: 'Trailer',
        },
      ],
      desc: 'Includes use of the track in a film, documentary or in a series with a budget of less than EUR 5M',
      title: 'Small/Medium Budget <5M EUR',
    },
    {
      children: [
        {
          desc: 'Online, cinema, TV',
          key: 'filmLargeAllMedia',
          title: 'All Media',
        },
        {
          desc: 'Online only',
          key: 'filmLargeOnlineOnly',
          title: 'Online Only',
        },
        {
          desc: 'Trailer for a film, series or documentary',
          key: 'filmLargeTrailer',
          title: 'Trailer',
        },
      ],
      desc: 'Includes use of the track in a film, documentary or in a series with a budget of more than EUR 5M',
      title: 'Large Budget >5M EUR',
    },
  ],
  desc: 'Includes use of the track in a film, documentary or in an episode of a series to be shown online, public cinema or TV',
  title: 'Film',
}

const categories: MusicLibraryContentTypeCategory[] = [
  socialMediaCategory,
  podcastCategory,
  corporateNonProfitCategory,
  gamesAppsCategory,
  advertisementCategory,
  radioTvCategory,
  filmCategory,
]

export const musicLibraryContentTypeOptions = categories.flatMap((category) => ({
  label: category.title,
  options: category.children.flatMap((topLevel) =>
    'children' in topLevel
      ? topLevel.children.map((child) => ({
          label: `${topLevel.title} / ${child.title}`,
          value: child.key,
        }))
      : {
          label: topLevel.title,
          value: topLevel.key,
        },
  ),
}))

export default categories
